import { toLocaleFixed } from '@/utils'
import { mapState } from 'vuex'

export const scaling = {
    data() {
        return {
            NCUOptions: [
                { id: 1, cpu: 1, memory: 4 },
                { id: 2, cpu: 2, memory: 8 },
                { id: 3, cpu: 4, memory: 16 },
                { id: 4, cpu: 8, memory: 32 },
                { id: 5, cpu: 12, memory: 48 },
                { id: 6, cpu: 16, memory: 64 }
            ]
        }
    },
    methods: {
        toLocaleFixed(balance, precision = this.precision) {
            return toLocaleFixed(balance, precision)
        },
        currentNCU(ncu) {
            return this.NCUOptions.find(option => {
                return option.cpu === ncu
            })
        },
        currentNodePool(nodepoolId) {
            return this.nodePools.find(nodepool => nodepool.node_pool === nodepoolId)
        },
        getNodepoolFamily(nodepool) {
            if (nodepool.ssd) return 'ssd'
            if (nodepool.gpu_type) return 'gpu'
            return 'cpu'
        },
        getNodepoolPrimaryParameter(nodepool) {
            if (nodepool.ssd) return `${this.toLocaleFixed(nodepool.ssd, 0)} GB SSD`
            if (nodepool.gpu_type) return nodepool.gpu_type
            return `${nodepool.cpu} vCPU`
        }
    },
    computed: {
        ...mapState('appStore', ['nodePools', 'fetchingNodePools'])
    }
}
